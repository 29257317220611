<script lang="ts" setup>
import {computed} from 'vue'
import type {DefineComponent} from 'vue'

const props = defineProps({
  showText: {
    type: Boolean,
    default: true,
  },
  showIcon: {
    type: Boolean,
    default: true,
  },
  icon: {
    type: String,
    default: 'gamepad',
  },
  text: {
    type: String,
    default: 'Find players',
  },
  colorStart: {
    type: String,
    default: 'from-violet-500',
  },
  colorEnd: {
    type: String,
    default: 'to-blue-400',
  },
  rounding: {
    type: String,
    default: 'rounded-l rounded-r-full',
  },
  glow: {
    type: Boolean,
    default: true,
  },
  customIcon: {
    type: Object as () => DefineComponent<{}, {}, any>,
    default: undefined,
  },
})

const gradientClass = computed(() => `bg-gradient-to-r ${props.colorStart} ${props.colorEnd}`)
const buttonClass = computed(() => {
  const baseClass = `${gradientClass.value} ${props.rounding} justify-${props.showIcon ? 'between' : 'center'}`
  return props.showIcon ? `${baseClass} ml-6` : baseClass
})
const iconClass = computed(() => `bg-gradient-to-r ${props.colorStart} ${props.colorEnd} rounded-full`)
const glowClass = computed(() => {
  const baseClass = `absolute inset-0 rounded-full ${gradientClass.value} opacity-40 blur transition duration-1000 group-hover:opacity-100 group-hover:duration-200`
  return props.showIcon ? `${baseClass} -mr-6` : baseClass
})
const glowIconClass = computed(() => `absolute -inset-0.5 rounded-full ${gradientClass.value} opacity-100 blur`)

function onClick() {
  console.log('Button clicked')
}
</script>

<template>
  <div class="grid" @click="onClick">
    <div class="group relative w-full">
      <div
        v-show="glow"
        :class="glowClass"
      />
      <button
        :class="buttonClass"
        aria-label="button"
        class="relative mx-auto flex h-10 w-full items-center gap-6 overflow-visible font-medium leading-none shadow-lg"
        type="button"
      >
        <span v-if="showIcon" class="absolute -left-6 flex">
          <span
            v-if="!customIcon"
            :class="iconClass"
            class="z-10 flex size-10 items-center justify-center rounded-full text-white shadow-xl"
          >
            <NuxtIcon :name="icon" class="text-2xl font-bold" fill />
          </span>
          <span
            v-else
            :class="iconClass"
            class="z-10 flex size-10 items-center justify-center rounded-full text-white shadow-xl"
          >
            <component :is="customIcon" class="size-6" />
          </span>
          <span
            v-show="glow"
            :class="glowIconClass"
          />
        </span>
        <span v-show="showText" class="flex grow flex-col pl-4 pr-1">
          <strong class="font-medium text-gray-100">{{ text }}</strong>
        </span>
      </button>
    </div>
  </div>
</template>
